<template>
    <div>
        <div class="container">
            <edit api="shippings" title="Delivery" :id="id" :fs="shippingFields" back="/admin/shippings" @saved="saved"></edit>
        </div>
    </div>
</template>

<script>
import Edit from "@/components/Edit";
import { shippingFields } from "@/config";
export default {
    props: ["id"],
    data() {
        return {
            shippingFields,
        };
    },
    components: {
        Edit,
    },
    methods: {
        saved() {
            this.$router.push("/admin/shippings");
        }
    },
  
};
</script>